<template>
  <svg viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6469 33.8935V0.147949H18.5088V33.8935H16.6469ZM0.705078 17.9516V16.0898H34.4506V17.9516H0.705078Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPlusMaterial'
}
</script>
